
import React from 'react';

import { Container, Row, Col, Spinner } from 'react-bootstrap';

import {RAGhostingPercent, RAGhostingDisk, HostingMergeToData, pad, timedateConvert, daysOld, byteToMegabyte, secondstotime} from './Library';

import Expansion from './Expansion';






const HostingFirstStageContent = (obj) => {
    //console.log('object',obj)

    const container = {}

    container.Item = obj.index;
    container.domain = obj.domain
//            container.domain = '<a href="https://www.' + obj.domain + '">' + obj.domain + '</a>';
    container.Url = 'https://www.'+ obj.domain;

    if (!obj.hasOwnProperty('hosting')) {

        container.suspended = "";
        container.TDsuspended = "";

        container.diskused = "";
        container.TDdiskused = "";

        container.disklimit = "";
        container.TDdisklimit = "";

        container.diskpercent = "";
        container.TDdiskpercent = "";

        container.user = "";
        container.email = "";

        container.ownerId = "";
    }
    else {
        if (obj.hosting.suspended == 1)
        {
            container.suspended = 'suspend';
            container.TDsuspended = "Amber";
        }
        else
        {
            container.suspended = 'active';
            container.TDsuspended = "Blue";
        }

        container.diskused = parseInt(obj.hosting.diskused);
        container.TDdiskused = RAGhostingDisk(container.diskused);

        container.disklimit = parseInt(obj.hosting.disklimit);
        container.TDdisklimit = "number";

        container.diskpercent = parseInt(container.diskused / container.disklimit * 100);
        container.TDdiskpercent = RAGhostingPercent(container.diskpercent);

        container.user = obj.hosting.user;
        container.email = obj.hosting.email;
        container.ownerId = obj.hosting.ownerId;
    }

    if (!obj.hasOwnProperty('bandwidth')) {

        container.totalbytes = "";
        container.TDtotalbytes = "";

        container.limit = "";
        container.TDlimit = "";

        container.bandpercent = "";
        container.TDbandpercent = "";

    } else {

        container.totalbytes = byteToMegabyte(obj.bandwidth.totalbytes);
        container.TDtotalbytes = "number";

        container.limit = byteToMegabyte(obj.bandwidth.limit);
        container.TDlimit = "number";

        container.bandpercent = parseInt(container.totalbytes / container.limit * 100);
        container.TDbandpercent = RAGhostingPercent(container.bandpercent);
    }

    return (container);
}


// const Status = ({ data }) => {
class StatusInformation extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {expand: [false, false, false] };
        
        this.expand = this.expand.bind(this);
    }


    expand = (index) => {
        const newState = this.state.expand;

        if (newState[index]) {
            // here if index is currently expanded
            newState[index] = false; 
        } else {
            // here if index item is currently not expanded
            newState.forEach((element, index) => newState[index] = false); 
            newState[index] = true; 
        } 

        this.setState({expand: newState});
    }
     

    //const function timedateConvert(timestamp) {

    //console.log(data);
    render() {

        if (this.props.data.length === 0) { // if no data then do nothing
            return null
        }

        const expand = this.state.expand;


        const diIndex = 2;
        const baIndex = 1;
        const miIndex = 0;

        const diState = expand[diIndex];
        const baState = expand[baIndex];
        const miState = expand[miIndex];

        const diCols = diState ? 3 : 1;
        const baCols = baState ? 3 : 1;
        const miCols = miState ? 3 : 1;

        //console.log(this.props.data);

        const firststage = this.props.data.map((obj) => {
            return HostingFirstStageContent(obj);
        })


        const listing = firststage.map((obj) => (
            <tr className="HostingStatusRow" key={obj.domain}>
                <td className="number">{obj.Item}</td>
                <td className="domain"><a href={obj.Url}>{obj.domain}</a></td>

                <td className={obj.TDsuspended}>{obj.suspended}</td>

                {diState && <td className={obj.TDdiskused}>{obj.diskused.toLocaleString("en-UK")}</td>}
                {diState && <td className={obj.TDdisklimit}>{obj.disklimit.toLocaleString("en-UK")}</td>}
                <td className={obj.TDdiskpercent}>{obj.diskpercent}</td>

                {baState && <td className={obj.TDtotalbytes}>{obj.totalbytes.toLocaleString("en-UK")}</td>}
                {baState && <td className={obj.TDlimit}>{obj.limit.toLocaleString("en-UK")}</td>}
                <td className={obj.TDbandpercent}>{obj.bandpercent}</td>

                <td className="misc">{obj.user}</td>
                {miState && <td className="misc">{obj.email}</td>}   
                {miState && <td className="misc">{obj.ownerId}</td>}

            </tr>
        ))

       //const summary = (prev, current) => parseInt(prev.diskused, 10) + parseInt(current.diskused, 10);

       //console.log('summary', firststage.reduce(summary));

       //console.log('firststage', firststage.reduce((a, b) => +a.diskused + +b.diskused),  firststage.map(i => i.diskused).reduce((a, b) => +a + +b));
       
       
       ///firststage.map(i=>i.diskused).reduce((a,b)=>parseInt(a)+parseInt(b)));

    
       const totals =  (!diState && !baState)  ? [] :

                        [
                            <tr className="HostingStatusRow" key="total">
                                <td className="HostingStatusTotalBlank"></td>
                                <td className="HostingStatusTotalTitle">TOTAL</td>
                                <td className="HostingStatusTotalBlank"></td>

                                {diState && <td className="HostingStatusTotal">{firststage.map(i => i.diskused).reduce((a, b) => +a + +b).toLocaleString("en-UK")}</td>}
                                {diState && <td className="HostingStatusTotalBlank"></td>}
                                <td className="HostingStatusTotalBlank"></td>

                                {baState && <td className="HostingStatusTotal">{firststage.map(i => i.totalbytes).reduce((a, b) => +a + +b).toLocaleString("en-UK")}</td>}
                                {baState && <td className="HostingStatusTotalBlank"></td>}
                                <td className="HostingStatusTotalBlank"></td>

                                <td className="HostingStatusTotalBlank"></td>
                                {miState && <td className="HostingStatusTotalBlank"></td>}
                                {miState && <td className="HostingStatusTotalBlank"></td>}
                            </tr>
                        ];

        const output = [
            <table className="HostingStatusTable" key="HostingStatusTable">
                <tbody>
                    <tr>
                        <th className="HostingStatusTitleBlank"></th>
                        <th className="HostingStatusTitleBlank"></th>
                        <th className="HostingStatusTitleBlank"></th>
                        <th className="HostingStatusTitle" colSpan={diCols}><Expansion index={diIndex} title={"Disk"} states={this.state.expand} toggle={this.expand}/></th>
                        <th className="HostingStatusTitle" colSpan={baCols}><Expansion index={baIndex} title={"B/W"} states={this.state.expand} toggle={this.expand}/></th>
                        <th className="HostingStatusTitle" colSpan={miCols}><Expansion index={miIndex} title={"Misc"} states={this.state.expand} toggle={this.expand}/></th>
                    </tr>
                    <tr>
                        <th className="HostingStatusTitle">Item</th>
                        <th className="HostingStatusTitle">Site</th>

                        <th className="HostingStatusTitle">Status</th>

                        {diState && <th className="HostingStatusTitle">Used</th>}
                        {diState && <th className="HostingStatusTitle">Max MB</th>}
                        <th className="HostingStatusTitle">%</th>

                        {baState && <th className="HostingStatusTitle">Used</th>}
                        {baState && <th className="HostingStatusTitle">Max MB</th>}
                        <th className="HostingStatusTitle">%</th>

                        <th className="HostingStatusTitle">User</th>
                        {miState && <th className="HostingStatusTitle">Email</th>}
                        {miState && <th className="HostingStatusTitle">Owner ID</th>}
                    </tr>
                    {listing}
                    {totals}
                </tbody>
            </table>
        ]

 


        return (
            <>
                {output}
            </>
        )
    }

}



class Epdhosting extends React.Component {
    constructor() {
        super();
        //this.state = { data: [] };
    }




/*
    componentDidMount() {
        ApiGet('domain') 
        .then(res => {
            const domains = res.Items;
            ApiGet('hosting') 
            .then(res => {
                const newdata = HostingMergeToData(res.listaccts.data.acct, res.showbw.data.acct, domains);
                this.setState({ data: newdata });
            });
        });
    }
    
*/ 
    render() { 

        console.log('Epdhosting', this.props.state.data);

        const textStyle = {
            textAlign: "center"
        };
    
        if (this.props.state.data.length === 0) {

            return (

                <Container fluid="lg">
                    <Row>
                        <Col>
                            <h1 style={textStyle}>Hosting Status</h1>
                            <div style={textStyle}><Spinner  animation="border" /></div>
                        </Col>
                    </Row>
                </Container>
            );

        }
        else {

            return (
                <Container fluid="lg">
                    <Row>
                        <Col>
                             <h1 style={textStyle}>Hosting Status</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <StatusInformation data={this.props.state.data} />
                        </Col>
                    </Row>
                </Container>

            );
        }
    }
}

export {Epdhosting, HostingFirstStageContent };


