import React, { useEffect } from 'react';
//import logo from './logo.svg';
import './App.css';
//import * as ReactBootstrap from 'react-bootstrap';
import { Auth, Amplify } from 'aws-amplify';

//import awsconfig from './aws-exports';
//import { AmplifyAuthenticator, withAuthenticator, AmplifySignOut, AmplifySignIn } from '@aws-amplify/ui-react';
//import Authentication from './Authentication.js';
//import AuthStateApp from './AuthState.js';
//import { AuthState } from '@aws-amplify/ui-components';




import { Hub } from 'aws-amplify';
import Logo from './epdlogo_lg.jpg'
import { Button, Container, Row, Col, Navbar, Nav, ButtonToolbar } from 'react-bootstrap';

import { Route, Switch, Redirect, withRouter, useLocation, useHistory} from 'react-router-dom';

import Home from './Home';
import Epdanalytics from './Epdanalytics';
import Epddetails from './Epddetails';
import {Epduptime} from './Epduptime';
import {Epdbackupstatus} from './Epdbackupstatus';
import {Epdsites} from './Epdsites';
import Epdsummary from './Epdsummary';
import {Epdhosting} from './Epdhosting';
import {Epdinformation} from './Epdinformation';
import {Epdregistrar} from './Epdregistrar';
import NavConfirm from './NavConfirm';
import Refresh from './Refresh';

import {contentFsm, EpdFullApiLoad, EpdSmartApiLoad} from './Library'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRefresh } from '@fortawesome/free-solid-svg-icons'

import ReactGA from 'react-ga4';


import {onCLS, onFID, onLCP} from 'web-vitals';

import { awsconfig } from './config';
//console.log('awsconfig', JSON.stringify(awsconfig, null, 2));
Amplify.configure(awsconfig);

function sendToGoogleAnalytics({name, delta, value, id}) {
/*
  // Assumes the global `gtag()` function exists, see:
  // https://developers.google.com/analytics/devguides/collection/ga4
  gtag('event', name, {
    // Built-in params:
    value: delta, // Use `delta` so the value can be summed.
    // Custom params:
    metric_id: id, // Needed to aggregate events.
    metric_value: value, // Optional.
    metric_delta: delta, // Optional.

    // OPTIONAL: any additional params or debug info here.
    // See: https://web.dev/debug-performance-in-the-field/
    // metric_rating: 'good' | 'needs-improvement' | 'poor',
    // debug_info: '...',
    // ...



  });
*/
  console.log("XXXXXXXXXXX~~~~~~~~~~~~~~~XXXXXX GA", name, delta, value, id);
}

onCLS(sendToGoogleAnalytics);
onFID(sendToGoogleAnalytics);
onLCP(sendToGoogleAnalytics);
 

const SetState = (props) => {

    useEffect(() => {

        console.log('USEFFECT-----------', props);
        (props.state.data.length === 0) && EpdFullApiLoad(props.setState);

    },[props]
    )

    return null;

}

class xxxSetState extends React.Component {
    constructor(props) {
        super(props);
    }


    componentDidMount() {
        console.log('MOUNT------------------------------------------------------------');
        (this.props.state.data.length === 0) && EpdFullApiLoad(this.props.setState);
    }


 
    render() {return(null)}

       
}
//     <SetState state={this.state} setState={this.setState}/>


class Action extends React.Component {
    constructor(props) {                 
        super(props);
    }


    componentDidMount() {
        console.log('Action StateChange Mounted')
        if (this.props.hasOwnProperty('actionParam')) {
            this.props.action(this.props.actionParam);
        } else {
            this.props.action();
        }
    } 


    render() {

        console.log('Action StateChange', this.props);

        return null;
    }
}
        
const RenderContent = ({props}) =>  {

    console.log('RenderContent', props, props.state);

    const textCenter = {
        textAlign: "center"
    };

    const textRight = {
        textAlign: "right"
    };

    const rowStyle = {
        // height: '100vw',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
       // marginBottom: '1rem'
        padding: '1rem'
    };


    switch (props.state.authy) {
        case contentFsm.UNDEFINED:
            console.log('RenderContent UNDEFINED');
            props.setauthstate('render');
            return (null);


        case contentFsm.REQUEST_SIGN_IN:
            console.log('RenderContent REQUEST_SIGN_IN');
            return (           


                    <Container fluid="lg">
                     <Navbar collapseOnSelect bg="light" expand="lg" sticky="top" className="menu_row" >
                        <Navbar.Toggle aria-controls="responsive-navbar-nav"  className="order-md-0 order-0" />
                            <Navbar.Collapse id="reponsive-navbar-nav">
                                <Nav className="mr-auto" onSelect={(selectedKey) => props.handleSelect(selectedKey)}>
                                    <Nav.Link eventKey="/cancel">Home</Nav.Link>
                                </Nav>
                            </Navbar.Collapse>

                            <Nav className="mr" onSelect={(selectedKey) => props.handleSelect(selectedKey)}>
                                <Nav.Link eventKey="/cancel"><Button variant="secondary">Cancel</Button></Nav.Link> 
                                {/*<Nav.Link eventKey="/signin"> <Button variant="primary" onClick={this.signin}>Sign In</Button></Nav.Link> */}
                            </Nav>
                    </Navbar>

                    <Row style={rowStyle}>
                        <Col>
                            <Switch>
                                <Route path="/cancel"><Action action={props.cancel}/></Route>    
                                {/*<Route><Authentication/></Route>   */}                              
                            </Switch>
                        </Col>
                    </Row>
                </Container>
            );


        case contentFsm.REQUEST_SIGN_OUT:
            console.log('RenderContent REQUEST_SIGN_OUT');
            Auth.signOut()
                .catch(
                    err => {
                        console.log('err: ', err);

                    });
           // props.setState({ authy: contentFsm.SIGNED_OUT });
            return (null);

        case contentFsm.SIGNED_OUT:
            console.log('RenderContent SIGNED_OUT');
            return (
                <Container fluid="lg">
                     <Navbar collapseOnSelect bg="light" expand="lg" sticky="top" className="menu_row" >
                        <Navbar.Toggle aria-controls="responsive-navbar-nav"  className="order-md-0 order-0" />
                            <Navbar.Collapse id="reponsive-navbar-nav">
                                <Nav className="mr-auto" onSelect={(selectedKey) => props.handleSelect(selectedKey)}>
                                    <Nav.Link eventKey="/home">Home</Nav.Link>
                                </Nav>
                            </Navbar.Collapse>
                            <Nav className="mr" onSelect={(selectedKey) => props.handleSelect(selectedKey)}>
                                <Nav.Link eventKey="/signin"><Button variant="primary">Sign In</Button></Nav.Link> 
                                {/*<Nav.Link eventKey="/signin"> <Button variant="primary" onClick={props.signin}>Sign In</Button></Nav.Link> */}
                            </Nav>
                    </Navbar>

                    <Row style={rowStyle}>
                        <Col>
                            <Switch>
                                <Route path="/signin"><Action action={props.signin} actionParam={props.lastLocation.pathname.substring("/signin/".length)}/></Route>
                                <Route><Home state={props.state}/></Route>   
                            </Switch>
                        </Col>
                    </Row>
               
                </Container>
            );
                

        case contentFsm.SIGNED_IN:
            console.log('RenderContent SIGNED_IN');
            return (
                <Container fluid="lg">
                    <Navbar collapseOnSelect bg="light" expand="lg" sticky="top" className="menu_row" >
                        <Navbar.Toggle aria-controls="responsive-navbar-nav"  className="order-md-0 order-0" />
                        <Navbar.Collapse id="reponsive-navbar-nav">
                            <Nav className="mr-auto" onSelect={(selectedKey) => props.handleSelect(selectedKey)}>
                                <Nav.Link eventKey="/home">Home</Nav.Link>
                                <Nav.Link eventKey="/summary">Summary</Nav.Link>
                                <Nav.Link eventKey="/sites">Sites</Nav.Link>
                                <Nav.Link eventKey="/status">Backups</Nav.Link>
                                <Nav.Link eventKey="/uptime">Uptime </Nav.Link>
                                <Nav.Link eventKey="/hosting">Hosting </Nav.Link>
                                <Nav.Link eventKey="/information">Information</Nav.Link>
                                <Nav.Link eventKey="/registrar">Registrar</Nav.Link>
                                {/* PRE GA4 <Nav.Link eventKey="/analytics">Analytics</Nav.Link>*/}
                                {/* PRE GA4 <Nav.Link eventKey="/details">Details</Nav.Link>*/}
                                {/*<Nav.Link eventKey="/signoutrequest">Signout Request</Nav.Link>*/}
                            </Nav>
                        </Navbar.Collapse>
                        <Nav className="mr" onSelect={(selectedKey) => props.handleSelect(selectedKey)}>
                            <ButtonToolbar>
                            <Nav.Link eventKey="/refresh"><Refresh state={props.state} /></Nav.Link> 
                            <Nav.Link eventKey="/signoutrequest"><Button variant="secondary">Sign Out</Button></Nav.Link> 
                            </ButtonToolbar>
                        </Nav>
                    </Navbar>

                   
                    <Row style={rowStyle}>
                        <Col>
                        <Switch>
                            <Route path="/summary"><Epdsummary state={props.state}/></Route>
                            <Route path="/sites"><Epdsites state={props.state}/></Route>
                            <Route path="/status"><Epdbackupstatus state={props.state}/></Route>
                            {/* PRE GA4 <Route path="/analytics"><Epdanalytics state={props.state}/></Route>*/}
                            <Route path="/hosting"><Epdhosting state={props.state}/></Route>
                            <Route path="/information"><Epdinformation state={props.state}/></Route>
                            <Route path="/registrar"><Epdregistrar state={props.state}/></Route>
                            <Route path="/uptime"><Epduptime state={props.state}/></Route>
                            {/* PRE GA4 <Route path="/details"><Epddetails state={props.state}/></Route>*/}
                            <Route path="/signout"><Action action={props.signout}/></Route>
                            {/*<Route path="/signoutrequest"><NavConfirm state={props.state} linkButton="true" buttonVariant="secondary" linkText="Sign Out" heading="Confirm Sign Out" description="Click Yes to confirm" pathYes="/signout" pathNo={props.lastLocation.pathname}  history={props.props.history} /></Route>*/}
                            <Route path="/signoutrequest"><NavConfirm state={props.state} linkButton="true" buttonVariant="secondary" linkText="Sign Out" heading="Confirm Sign Out" description="Click Yes to confirm" pathYes="/signout" pathNo="/" history={props.props.history} /></Route>
                           <Route><Home state={props.state}/></Route>
                        </Switch>    
                        </Col>
                    </Row>
                    
                </Container>
            );


        default:
            return (<div><p>Unknown State {props.state.authy}</p></div>);
    } 

}

class Content extends React.Component {
    constructor(props) {                 
        super(props);
        this.state = { authy: contentFsm.UNDEFINED, user: [], data: [], lastUpdate: null, err: "", loading: false, mounted: false };

        this.lastLocation = this.props.history.location;
        this.handleSelect = this.handleSelect.bind(this);
        this.setState = this.setState.bind(this);
        this.signin = this.signin.bind(this);
        this.signout = this.signout.bind(this);
        this.cancel = this.cancel.bind(this);


        this.currentLocation = this.props.history.location;

        console.log('Content Constructor', this.currentLocation, this.currentLocation.hasOwnProperty('pathname'));

        console.log('SPA new page', this.currentLocation.pathname);
        ReactGA.send({ hitType: "pageview", page: this.currentLocation.pathname, title: this.currentLocation.pathname });

        
        

        this.props.history.listen((location, action) => {

            if (location.pathname !== this.currentLocation.pathname)
            {
                console.log('SPA page change', action, location.pathname,  this.currentLocation.pathname);
                ReactGA.send({ hitType: "pageview", page: location.pathname, title: location.pathname });
                this.currentLocation.pathname = location.pathname;
            }
            else {
                console.log('SPA page skip');
            }
            
        });

      }


    componentDidMount() {

        Hub.listen('auth', (data) => {
            console.log('?????????????????? Hub.listen',data );
            const { payload } = data;
            this.onAuthEvent(payload);
        });
        this.setState({mounted: true});
    } 

    componentDidUpdate() {
        console.log('EEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE', this.currentLocation.pathname);
    } 



    onAuthEvent(payload) {
        console.log('auth event============', payload.event);
        this.setauthstate(payload.event);
    }



    signin = (actionParam) => {
        console.log('signin function', actionParam);
        //console.log('getCurrentUser', Auth.currentAuthenticatedUser());
        Auth.federatedSignIn({provider: actionParam});
        //Auth.federatedSignIn({provider: 'Cognito'});
        //Auth.federatedSignIn({provider: 'Google'});
        //Auth.federatedSignIn({provider: 'LogInWithAuth0'});
        //if (this.state.authy !== contentFsm.REQUEST_SIGN_IN) {
        //    this.setState({ authy: contentFsm.REQUEST_SIGN_IN });
        //}
    }

    signout = () => {
        if (this.state.authy !== contentFsm.REQUEST_SIGN_OUT) {
            this.setState({ authy: contentFsm.REQUEST_SIGN_OUT });
        }
    }

    cancel = () => {
            this.setState({ authy: contentFsm.SIGNED_OUT});
    }

    setauthstate = (event) => {
        console.log('set Auth State+++++++++++++', Auth, event, this.state.authy); 
        Auth.currentAuthenticatedUser() // if we have a user then we are logged on
            .then( 
                user => {
                    if (this.state.authy !== contentFsm.SIGNED_IN) {
                        // set to logged in

                        console.log('user: ', user);
                        this.setState({ authy: contentFsm.SIGNED_IN });
                        //(this.props.state.data.length === 0) && EpdFullApiLoad(this.props.setState);

                        switch (this.props.history.location.pathname) {
                            case "/home":
                            case "/":
                                // no data loading as this is a new invocation on the home page
                                break;

                            default:
                                EpdSmartApiLoad(this.state, this.setState, "maximum");
                        }
                    }
            })
            .catch(
                err => {
                    switch (this.state.authy) {
                        case contentFsm.SIGNED_OUT:         break;
                        case contentFsm.REQUEST_SIGN_IN: 
                                                            if (event === 'forgotPassword') {
                                                                this.setState({ authy: contentFsm.REQUEST_SIGN_IN });
                                                            }
                                                            break;
                        default:
                                                            console.log('err: ', err);
                                                            this.setState({ authy: contentFsm.SIGNED_OUT });
                                                            EpdSmartApiLoad(this.state, this.setState, "unload"); // UNLOAD
                    }
            });
    }

    handleSelect(key) {
        

        this.lastLocation = this.props.history.location;
        console.log('NAV keyevent', key)
        switch (key) {

            case '/home': 
                        if (this.state.authy === contentFsm.SIGNED_IN) { 
                            //EpdSmartApiLoad(this.state, this.setState, "unload"); // UNLOAD
                            EpdSmartApiLoad(this.state, this.setState, "maximum"); 
                        } 
                        this.props.history.push(key);
                        break;

            case '/refresh': 
                        if (this.state.authy === contentFsm.SIGNED_IN) { 
                            EpdSmartApiLoad(this.state, this.setState, "minimum"); 
                        } 
                        break;
                    
            default: {
                        if (this.state.authy === contentFsm.SIGNED_IN) {
                            EpdSmartApiLoad(this.state, this.setState, "maximum");
                        }
                        this.props.history.push(key)
            }
        }
        
    }

    render() {

     

        console.log('Content', this.state, this.props.history.location, this.lastLocation)

        if (!this.state.mounted) return null;
        //Auth.currentAuthenticatedUser().then(user => console.log('user: ', user)).catch(err => console.log('err: ', err));

        console.log('SSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS', this.currentLocation.pathname);


        return(<><RenderContent props={{state: this.state, 
                                        setauthstate: this.setauthstate,
                                        signout: this.signout,
                                        signin: this.signin,
                                        cancel: this.cancel,
                                        handleSelect: this.handleSelect,
                                        lastLocation: this.lastLocation,
                                        props: this.props
                                       }}/></>)

     
        
    }
}
export default withRouter(Content);

