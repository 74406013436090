
// https://stackoverflow.com/questions/56462796/how-to-generate-aws-exports-js-with-existing-user-pool-in-aws-cognito
// https://docs.amplify.aws/gen1/javascript/tools/libraries/configure-categories/

import { EpdSmartApiLoad } from "./Library";

function setConfig() {

    const dev = {

        Environment: 'development',

        Auth: {

            region: 'eu-west-2',
            userPoolId: 'eu-west-2_ZTR4bbxZN',
            userPoolWebClientId: '6g6stmbin0tk4rdmdotc29id9l',

            API: {
                endpoints: [
                    {
                        name: 'epdstatus-API-Gateway-dev',
                        endpoint: 'https://17q9vlpfhf.execute-api.eu-west-2.amazonaws.com',
                    }
                ]
            }
        },
        ApiGateway: {

            apiName: 'epdstatus-API-Gateway-dev',
            apiPath: '/api/',

            apiInit: {
                headers: {
                    "Access-Control-Allow-Headers": "*",
                    "Access-Control-Allow-Methods": "*",
                    "Access-Control-Allow-Origin": "*"
                }
            }
        }
    }

    const stage = {
 
        Environment: 'staging',

        Auth: {

            region: 'eu-west-2',
            userPoolId: 'eu-west-2_ZTR4bbxZN',
            userPoolWebClientId: '6tb02ku99s14nnli2h3u7gc28n', /* CloudFront */
            API: {
                endpoints: [
                    {
                        name: "epdstatus-API-Gateway-dev",
                        endpoint: " https://dev.admin.easypeasydigital.com"
                    }
                ]
            }
        },
        ApiGateway: {  // stage uses the dev instance of api gateway

            apiName: 'epdstatus-API-Gateway-dev',
            apiPath: '/api/', // the (dev) environment for api gateway is provided by cloudfront

            apiInit: {
                headers: {
                    "Access-Control-Allow-Headers": "*",
                    "Access-Control-Allow-Methods": "*",
                    "Access-Control-Allow-Origin": "*"
                }
            }
        }
    }



    const prod = {

        Environment: '',

        Auth: {

            //Cognito: {
            //identityPoolId: '',
            //identityPoolRegion: '',
            //  region: 'eu-west-2',
            userPoolId: 'eu-west-2_quwRLXs2J', 
            userPoolWebClientId: '1evr7lcata1d6fogpt19d6epnj', 
            oauth: {
                domain: 'epdstatus.auth.eu-west-2.amazoncognito.com',
                scopes: ['openid', 'email', 'profile'],
                redirectSignIn: 'https://admin.easypeasydigital.com',
                redirectSignOut: 'https://admin.easypeasydigital.com',
                //redirectSignIn: 'https://www.easypeasydigital.co.uk/openid-connect-authorize',
                //redirectSignOut: 'https://www.easypeasydigital.co.uk',
                responseType: 'code',
            },

            API: {
                endpoints: [
                    {
                        name: "epdstatus-API-Gateway",
                        endpoint: "https://admin.easypeasydigital.com",
                    }
                ]
            },
        //},
         //   Googlesxxx: {
         //       region: 'eu-west-2',
         //       userPoolId: 'eu-west-2_quwRLXs2J', 
          //      userPoolWebClientId: '1evr7lcata1d6fogpt19d6epnj', 
                /*LoginWith: { // optional
                    oauth: {
                        domain: 'https://epdstatus.auth.eu-west-2.amazoncognito.com',
                        scopes: ['openid', 'email', 'profile'],
                        redirectSignIn: ['https://admin.easypeasydigital.com'],
                        redirectSignOut: ['https://admin.easypeasydigital.com'],
                        responseType: 'code',
                    },
                    userName: 'true',
                    email: 'true',
                    phone: 'false',
                }*/
           // }
        },
        ApiGateway: {

            apiName: 'epdstatus-API-Gateway',
            apiPath: '/api/', // the (prod) environment for api gateway is provided by cloudfront

            apiInit: {
                headers: {
                    "Access-Control-Allow-Headers": "*",
                    "Access-Control-Allow-Methods": "*",
                    "Access-Control-Allow-Origin": "*"
                }
            }
        }
    };



    switch (process.env.REACT_APP_STAGE.trim()) {
        case 'prod':
            return (prod);

        case 'stage':
            return (stage);

        case 'dev':
        default:
            return (dev);
    }
}


//const config = (process.env.REACT_APP_STAGE === 'prod') ? prod : dev;


const config = setConfig();

const awsconfig = config.Auth;
const environment = config.Environment + ' 1v16';
const apiGateway = config.ApiGateway;





//consconfit awsconfig = dev.Auth;
//const Environment = process.env.REACg.T_APP_STAGE;

export { awsconfig, environment, apiGateway };
